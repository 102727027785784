import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Effective IAM for Amazon Web Services"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "effective-iam-for-amazon-web-services",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#effective-iam-for-amazon-web-services",
        "aria-label": "effective iam for amazon web services permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Effective IAM for Amazon Web Services`}</h1>
    <p>{`By `}<a href="/about#why-im-a-relevant-source-of-advice">{`Stephen Kuenzli`}</a>{` `}<a href="https://twitter.com/skuenzli" target="_blank"><svg style={{
          "height": "1em"
        }} viewBox="0 0 24 24" aria-hidden="true" className="r-1cvl2hr r-4qtqp9 r-yyyyoo r-16y2uox r-8kz0gk r-dnmrzs r-bnwqim r-1plcrui r-lrvibr r-lrsllp"><g><path d="M23.643 4.937c-.835.37-1.732.62-2.675.733.962-.576 1.7-1.49 2.048-2.578-.9.534-1.897.922-2.958 1.13-.85-.904-2.06-1.47-3.4-1.47-2.572 0-4.658 2.086-4.658 4.66 0 .364.042.718.12 1.06-3.873-.195-7.304-2.05-9.602-4.868-.4.69-.63 1.49-.63 2.342 0 1.616.823 3.043 2.072 3.878-.764-.025-1.482-.234-2.11-.583v.06c0 2.257 1.605 4.14 3.737 4.568-.392.106-.803.162-1.227.162-.3 0-.593-.028-.877-.082.593 1.85 2.313 3.198 4.352 3.234-1.595 1.25-3.604 1.995-5.786 1.995-.376 0-.747-.022-1.112-.065 2.062 1.323 4.51 2.093 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602.91-.658 1.7-1.477 2.323-2.41z"></path></g></svg></a></p>
    <p><em parentName="p">{`Effective IAM for Amazon Web Services`}</em>{` is for Cloud engineers who design, develop, and review AWS IAM security policies in their daily work.`}</p>
    <p>{`If you're struggling to deliver effective AWS security policies, this guide will help you understand why it's hard and how both you and your organization can use IAM well.`}</p>
    <p>{`The AWS IAM documentation tells you what you can do. This guide will show you how to scale IAM best practices to all developers. `}</p>
    <p>{`Learn how to:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`solve`}</strong>{` difficult security problems using the best parts of AWS IAM`}</li>
      <li parentName="ul"><strong parentName="li">{`simplify`}</strong>{` AWS IAM into a set of secure infrastructure code building blocks to deliver changes quickly`}</li>
      <li parentName="ul"><strong parentName="li">{`verify`}</strong>{` AWS IAM security policies protect resources as intended`}</li>
      <li parentName="ul"><strong parentName="li">{`secure`}</strong>{` IAM continuously at any scale`}</li>
    </ul>
    <p>{`Learn more `}<a parentName="p" {...{
        "href": "/about"
      }}>{`about this book`}</a>{` or `}<a parentName="p" {...{
        "href": "/control-access-to-any-resource"
      }}>{`how to control access to any resource`}</a>{`.`}</p>
    <br />
    <div style={{
      "display": "block",
      "marginLeft": "auto",
      "marginRight": "auto",
      "width": "80%",
      "textAlign": "center"
    }}>
      <a href="http://eepurl.com/hCnCi5" target="_blank"><img className="button" alt="Get Updates button" src="/cta-btn.get-updates.png" /></a>
      <a href="https://store.effectiveiam.com/l/ZSCFy" target="_blank"><img className="button" alt="Buy Ebook button" src="/cta-btn.buy-ebook.png" /></a>
      <a href="https://www.amazon.com/Effective-IAM-AWS-continuous-delivery-ebook/dp/B0BJ7VLT5M" target="_blank"><img className="button" alt="Buy Kindle button" src="/cta-btn.buy-kindle.png" /></a>
    </div>
    <br />
    <hr />
    <br />
    <div style={{
      "display": "block",
      "marginLeft": "auto",
      "marginRight": "auto",
      "width": "75%",
      "textAlign": "center"
    }}>
      <h2>Reviews</h2>
    <div className="review">  
        <img src="/social/headshot.victor-grenu.circular.jpg" width="75px" height="75px" alt="Victor Grenu Profile Photo" style={{
          "verticalAlign": "middle"
        }} />
         <br />   
         <p className="reviewerName"><a href="http://twitter.com/zoph" target="_blank">Victor Grenu</a></p>
         <p className="reviewerTitle">Senior AWS Architect, Security Specialist</p>
         <p className="review">"IAM is HARD. Even AWS fails at it sometimes, in practice or in documentation. One of the best pieces of advice I give to my customers when I'm running AWS Security assessments is to recommend this book as a starting point for least privilege and a better understanding of the IAM landscape on AWS. It contains many examples and schemas that help to get a clear view of how IAM works under the hood, and what you can do to attain best security practices."</p>
    </div>
    <div className="review">  
        <img src="/social/headshot.will-button.circular.jpg" width="75px" height="75px" alt="Will Button Profile Photo" style={{
          "verticalAlign": "middle"
        }} />
         <br />   
         <p className="reviewerName"><a href="https://twitter.com/wfbutton" target="_blank">Will Button</a></p>
         <p className="reviewerTitle">Startup Technologist, Creator DevOps for Developers</p>
         <p className="review">"I've been working with AWS for over 10 years and I learned more in <a href="https://youtu.be/Qzlmwo1jQXo" target="_blank">this 30 minute conversation (video) with Stephen and the Effective IAM book</a> than I have in those past 10 years."</p>
    </div>
    <div className="review">  
        <img src="/social/headshot.wes-novack.circular.jpg" width="75px" height="75px" alt="Wes Novack Profile Photo" style={{
          "verticalAlign": "middle"
        }} />
         <br />   
         <p className="reviewerName"><a href="https://twitter.com/WesleyTech" target="_blank">Wes Novack</a></p>
         <p className="reviewerTitle">Technologist, Leader, Author</p>
         <p className="review">"In his book, Stephen does a wonderful job of explaining his recommended control loops, processes & culture to enable an organization to secure their AWS presence effectively & in a repeatable fashion."</p>
    </div>
    <div className="review">  
        <img src="/social/headshot.paul-swail.circular.jpg" width="75px" height="75px" alt="Paul Swail Profile Photo" style={{
          "verticalAlign": "middle"
        }} />
         <br />   
         <p className="reviewerName"><a href="https://serverlessfirst.com" target="_blank">Paul Swail</a></p>
         <p className="reviewerTitle">Serverless Expert & AWS Consultant at Winter Wind Software</p>
         <p className="review">"Do you deal with IAM regularly but still not properly understand it? If so, I highly recommend you check out Effective IAM for AWS.
                            Since I read it, I have a <em>much</em> clearer mental model on how to best define principals and policies for both security and maintainability."</p>
    </div>
    </div>
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      